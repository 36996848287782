var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"client-order","bladeName":"client-orders","bladesData":_vm.bladesData,"headers":[
        { text: 'CO#', value: 'courierOrderNumber', subtitle: 4, prefix: 'CO# ' },
        { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth' }, 
        { text: 'Client', value: 'buyer.companyName', subtitle: 1, prefix: 'Client: ' },
        { text: 'Departure From', value: 'departureLocation', display: true, subtitle: 3, prefix: 'From: ' },
        { text: 'Destination', value: 'destinationLocation', display: true, subtitle: 2, prefix: 'To: ' },
        { text: 'Status', value: 'id', display: true, subtitle: 5, prefix: 'Status: ' }],"navigation":"client-orders","title":"Client Orders","defaultBladeWidth":900},scopedSlots:_vm._u([{key:"settings",fn:function(){return [(_vm.$canView('courier-settings'))?_c('BT-Entity',{attrs:{"ignoreID":"","inline":"","navigation":"courier-settings","single":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
        var save = ref.save;
return [_c('v-divider'),_c('v-subheader',[_vm._v("Settings")]),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Approve Clients' Orders")])],1),_c('v-list-item-action',[_c('v-row',{attrs:{"dense":""}},[_c('BT-Toggle-Trigger-Icon',{attrs:{"disabled":!_vm.isGlobalSetting(item.courierRequestApprovalGuide)},on:{"change":save},model:{value:(item.courierRequestApprovalTrigger),callback:function ($$v) {_vm.$set(item, "courierRequestApprovalTrigger", $$v)},expression:"item.courierRequestApprovalTrigger"}}),_c('BT-Toggle-Guide-Icon',{on:{"change":save},model:{value:(item.courierRequestApprovalGuide),callback:function ($$v) {_vm.$set(item, "courierRequestApprovalGuide", $$v)},expression:"item.courierRequestApprovalGuide"}})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Readying Consignments")])],1),_c('v-list-item-action',[_c('v-row',{attrs:{"dense":""}},[_c('BT-Toggle-Trigger-Icon',{attrs:{"disabled":!_vm.isGlobalSetting(item.consignmentDispatchGuide)},on:{"change":save},model:{value:(item.consignmentDispatchTrigger),callback:function ($$v) {_vm.$set(item, "consignmentDispatchTrigger", $$v)},expression:"item.consignmentDispatchTrigger"}}),_c('BT-Toggle-Guide-Icon',{on:{"change":save},model:{value:(item.consignmentDispatchGuide),callback:function ($$v) {_vm.$set(item, "consignmentDispatchGuide", $$v)},expression:"item.consignmentDispatchGuide"}})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Who Usually Organizes Couriering")])],1),_c('v-list-item-action',[_c('v-row',{attrs:{"dense":""}},[_c('BT-Toggle-Trigger-Icon',{attrs:{"defaultValue":"Any","toggleOptions":[
                                    { text: 'Anyone', value: 'Any', icon: 'mdi-earth' },
                                    { text: 'Customer', value: 'Customer', icon: 'mdi-account' }, 
                                    { text: 'Supplier', value: 'Supplier', icon: 'mdi-factory' }]},on:{"change":save},model:{value:(item.courierSelectorTrigger),callback:function ($$v) {_vm.$set(item, "courierSelectorTrigger", $$v)},expression:"item.courierSelectorTrigger"}})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Last Courier Order Number")])],1),_c('v-list-item-action',[_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"prepend-icon":"#","singleLine":"","hideDetails":"","type":"number"},on:{"change":save},model:{value:(item.lastCourierOrderNumber),callback:function ($$v) {_vm.$set(item, "lastCourierOrderNumber", _vm._n($$v))},expression:"item.lastCourierOrderNumber"}})]},proxy:true}],null,true)},[_c('span',[_vm._v("#"+_vm._s(item.lastCourierOrderNumber))])])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Courier Billing")])],1),_c('v-list-item-action',[_c('v-row',{attrs:{"dense":""}},[_c('BT-Menu-Automation',{on:{"change":save},model:{value:(item.courierOrderBillAutomation),callback:function ($$v) {_vm.$set(item, "courierOrderBillAutomation", $$v)},expression:"item.courierOrderBillAutomation"}}),_c('BT-Toggle-Trigger-Icon',{attrs:{"disabled":!_vm.isGlobalSetting(item.courierOrderBillGuide)},on:{"change":save},model:{value:(item.courierOrderBillTrigger),callback:function ($$v) {_vm.$set(item, "courierOrderBillTrigger", $$v)},expression:"item.courierOrderBillTrigger"}}),_c('BT-Toggle-Guide-Icon',{on:{"change":save},model:{value:(item.courierOrderBillGuide),callback:function ($$v) {_vm.$set(item, "courierOrderBillGuide", $$v)},expression:"item.courierOrderBillGuide"}})],1)],1)],1)]}}],null,false,63792494)}):_vm._e()]},proxy:true},{key:"departureLocation",fn:function(ref){
                                    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("toFamiliarLocationLine")(item.departureLocation || item.requestedDepartureLocation)))])]}},{key:"destinationLocation",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toFamiliarLocationLine")(item.isPickup ? '*Pickup' : item.destinationLocation || item.requestedDestinationLocation))+" ")]}},{key:"id",fn:function(ref){
                                    var item = ref.item;
return [(item.fulfilledOn != null)?_c('span',[_vm._v("Fulfilled")]):(item.isBilled)?_c('span',[_vm._v("Billed")]):(item.isPlanned)?_c('span',[_vm._v("Planned")]):(item.isApproved === true)?_c('span',[_vm._v("Approved")]):(item.isApproved === false)?_c('span',[_vm._v("Rejected")]):(item.isApproved == null)?_c('span',[_vm._v("Unresponded")]):_vm._e()]}},{key:"itemActions",fn:function(ref){
                                    var item = ref.item;
return [(!item.isPlanned && item.isApproved && item.fulfilledOn == null)?_c('BT-Single-Action',{attrs:{"icon":item.isPickup ? 'mdi-pickaxe' : 'mdi-map-marker-distance',"loading":item.loadingCount > 0,"title":item.isPickup ? 'Pickup' : 'Move Directly'},on:{"click":function($event){return _vm.planDirectRoute(item)}}}):_vm._e(),(!item.isPickup && !item.isPlanned && item.isApproved && item.fulfilledOn == null && item.sellerID == item.buyerID)?_c('BT-Dialog',{attrs:{"icon":"mdi-map-marker-question-outline","getOnOpenAsync":_vm.getRouteSuggestionsAsync,"item":item,"label":"Route Suggestions","small":"","title":"Route Suggestion","width":"450"},on:{"ok":function (res) { _vm.createRoute(res, item) }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var item = ref.item;
return [(_vm.isLengthyArray(item.routeMovements))?_c('v-list',_vm._l((item.routeMovements),function(route,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[(index == 0)?_c('v-list-item-title',[_c('BT-Entity',{attrs:{"navigation":"public-locations","single":"","textFilter":"toLocationLine","useLocalCache":""},model:{value:(route.departureLocationID),callback:function ($$v) {_vm.$set(route, "departureLocationID", $$v)},expression:"route.departureLocationID"}})],1):_vm._e(),_c('v-list-item-subtitle',[_vm._v("To")]),_c('v-list-item-title',[_c('BT-Entity',{attrs:{"navigation":"public-locations","single":"","textFilter":"toLocationLine","useLocalCache":""},model:{value:(route.destinationLocationID),callback:function ($$v) {_vm.$set(route, "destinationLocationID", $$v)},expression:"route.destinationLocationID"}})],1)],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(_vm._s(_vm._f("toDayMonth")(route.dueArrivalOn)))])],1)],1)}),1):_c('div',[_vm._v("No Suggestions Found")])]}}],null,true)}):_vm._e(),(item.isApproved == null)?_c('BT-Single-Action',{attrs:{"loading":item.loadingCount > 0,"title":"Approve Order"},on:{"click":function($event){return _vm.approveCourierOrder(item)}}}):_vm._e(),(item.isApproved == null || (item.isApproved === true && item.fulfilledOn == null))?_c('BT-Single-Action',{staticClass:"error--text",attrs:{"loading":item.loadingCount > 0,"icon":"mdi-close","title":"Reject Order"},on:{"click":function($event){return _vm.rejectCourierOrder(item)}}}):_vm._e(),(item.fulfilledOn != null && !item.isBilled)?_c('BT-Single-Action',{attrs:{"loading":item.loadingCount > 0,"icon":"mdi-receipt","title":"Invoice Courier Order"},on:{"click":function($event){return _vm.invoiceCourierOrder(item)}}}):_vm._e(),_c('BT-Snack',{model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }