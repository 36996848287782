<template>
    <BT-Blade-Items
        addBladeName="client-order"
        bladeName="client-orders"
        :bladesData="bladesData"
        :headers="[
            { text: 'CO#', value: 'courierOrderNumber', subtitle: 4, prefix: 'CO# ' },
            { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth' }, 
            { text: 'Client', value: 'buyer.companyName', subtitle: 1, prefix: 'Client: ' },
            { text: 'Departure From', value: 'departureLocation', display: true, subtitle: 3, prefix: 'From: ' },
            { text: 'Destination', value: 'destinationLocation', display: true, subtitle: 2, prefix: 'To: ' },
            { text: 'Status', value: 'id', display: true, subtitle: 5, prefix: 'Status: ' }]"
        navigation="client-orders"
        title="Client Orders"
        :defaultBladeWidth="900">
        <template v-slot:settings>
            <BT-Entity
                v-if="$canView('courier-settings')"
                ignoreID
                inline
                navigation="courier-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-divider />
                    <v-subheader>Settings</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Approve Clients' Orders</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.courierRequestApprovalGuide)"
                                    v-model="item.courierRequestApprovalTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon
                                    v-model="item.courierRequestApprovalGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Readying Consignments</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.consignmentDispatchGuide)"
                                    v-model="item.consignmentDispatchTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon
                                    v-model="item.consignmentDispatchGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Who Usually Organizes Couriering</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Toggle-Trigger-Icon
                                    v-model="item.courierSelectorTrigger"
                                    defaultValue="Any"
                                    :toggleOptions="[
                                        { text: 'Anyone', value: 'Any', icon: 'mdi-earth' },
                                        { text: 'Customer', value: 'Customer', icon: 'mdi-account' }, 
                                        { text: 'Supplier', value: 'Supplier', icon: 'mdi-factory' }]"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Last Courier Order Number</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-edit-dialog>
                                <span>#{{ item.lastCourierOrderNumber }}</span>
                                <template v-slot:input>
                                    <v-text-field
                                        v-model.number="item.lastCourierOrderNumber"
                                        @change="save"
                                        prepend-icon="#"
                                        singleLine
                                        hideDetails
                                        type="number" />
                                </template>
                            </v-edit-dialog>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Courier Billing</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Menu-Automation v-model="item.courierOrderBillAutomation" @change="save" />
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.courierOrderBillGuide)"
                                    v-model="item.courierOrderBillTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon
                                    v-model="item.courierOrderBillGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </BT-Entity>
        </template>

        <template slot="departureLocation" slot-scope="{ item }">
            <div>{{ item.departureLocation || item.requestedDepartureLocation | toFamiliarLocationLine }}</div>
        </template>
        <template slot="destinationLocation" slot-scope="{ item }">
            {{ item.isPickup ? '*Pickup' : item.destinationLocation || item.requestedDestinationLocation | toFamiliarLocationLine }}
        </template>

        <template slot="id" slot-scope="{ item }">
            <span v-if="item.fulfilledOn != null">Fulfilled</span>
            <span v-else-if="item.isBilled">Billed</span>
            <span v-else-if="item.isPlanned">Planned</span>
            <span v-else-if="item.isApproved === true">Approved</span>
            <span v-else-if="item.isApproved === false">Rejected</span>
            <span v-else-if="item.isApproved == null">Unresponded</span>
        </template>

        <template v-slot:itemActions="{ item }">
            
            <BT-Single-Action  
                v-if="!item.isPlanned && item.isApproved && item.fulfilledOn == null"
                @click="planDirectRoute(item)"
                :icon="item.isPickup ? 'mdi-pickaxe' : 'mdi-map-marker-distance'"
                :loading="item.loadingCount > 0"
                :title="item.isPickup ? 'Pickup' : 'Move Directly'" />
            
            <BT-Dialog
                v-if="!item.isPickup && !item.isPlanned && item.isApproved && item.fulfilledOn == null && item.sellerID == item.buyerID"
                icon="mdi-map-marker-question-outline"
                @ok="res => { createRoute(res, item) }"
                :getOnOpenAsync="getRouteSuggestionsAsync"
                :item="item"
                label="Route Suggestions"
                small
                title="Route Suggestion"
                width="450">
                <template v-slot="{ item }">
                    <v-list v-if="isLengthyArray(item.routeMovements)">
                        <v-list-item v-for="(route, index) in item.routeMovements" :key="index">
                            <v-list-item-content>
                                <v-list-item-title v-if="index == 0">
                                    <BT-Entity 
                                        navigation="public-locations" 
                                        v-model="route.departureLocationID"
                                        single
                                        textFilter="toLocationLine"
                                        useLocalCache />
                                </v-list-item-title>
                                <v-list-item-subtitle>To</v-list-item-subtitle>
                                <v-list-item-title>
                                    <BT-Entity 
                                        navigation="public-locations" 
                                        v-model="route.destinationLocationID"
                                        single
                                        textFilter="toLocationLine"
                                        useLocalCache />
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>{{ route.dueArrivalOn | toDayMonth }}</v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <div v-else>No Suggestions Found</div>
                </template>
            </BT-Dialog>

                <!-- <BT-Single-Action-Dialog
                    v-if="!item.isPickup && !item.isPlanned && item.isApproved && item.fulfilledOn == null"
                    :item="item"
                    title="Move Via"
                    icon="mdi-map-marker-path"
                    doneIcon="mdi-map-check"
                    :isOnceOff="true"
                    :isDone="item.isPlanned"
                    :loading="item.loading"
                    @ok="planRouteVia"
                    :getOnOpen="getMovement"
                    okText="Move"
                    width="400">
                    <template slot-scope="{ item }">
                        <BT-Select    
                            v-if="item.movement != null"
                            navigation="locations"
                            itemText="locationName"
                            itemValue="id"
                            prependIcon="mdi-map-marker"
                            v-model="item.movement.intermediateLocationID"
                            label="Intermediate Location" />

                        <BT-Date-Picker
                        v-if="item.movement != null"
                            label="Departing On"
                            v-model="item.movement.dueDepartureOn" />

                        <BT-Date-Picker
                            v-if="item.movement != null"
                            label="Arriving On"
                            v-model="item.movement.dueArrivalOn" />

                    </template>
                </BT-Single-Action-Dialog> -->

                <BT-Single-Action
                    v-if="item.isApproved == null"
                    :loading="item.loadingCount > 0"
                    @click="approveCourierOrder(item)"
                    title="Approve Order" />

                <BT-Single-Action
                    v-if="item.isApproved == null || (item.isApproved === true && item.fulfilledOn == null)"
                    :loading="item.loadingCount > 0"
                    @click="rejectCourierOrder(item)"
                    icon="mdi-close"
                    class="error--text"
                    title="Reject Order" />

                <BT-Single-Action
                    v-if="item.fulfilledOn != null && !item.isBilled"
                    :loading="item.loadingCount > 0"
                    icon="mdi-receipt"
                    title="Invoice Courier Order"
                    @click="invoiceCourierOrder(item)" />
                    
            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Items>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Client-Orders-Blade',
    components: {
        BTSingleAction: () => import('~components/BT-Single-Action.vue'),
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTMenuAutomation: () => import('~components/BT-Menu-Automation.vue'),
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        BTToggleGuideIcon: () => import('~components/BT-Toggle-Guide-Icon.vue'),
    },
    data: function() {
        return {
            msg: null
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        async approveCourierOrder(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var data = {
                    id: item.id,
                    rowVersion: item.rowVersion,
                    departureLocationID: item.departureLocationID != null ? item.departureLocationID : item.requestedDepartureLocationID,
                    destinationLocationID: item.destinationLocationID != null ? item.destinationLocationID : item.requestedDestinationLocationID,
                    isApproved: true
                };

                var res = await this.$BlitzIt.store.patch('client-orders', data);
                
                item.departureLocationID = res.departureLocationID;
                item.departureLocation = item.departureLocation || item.requestedDepartureLocation;
                item.destinationLocationID = res.destinationLocationID;
                item.destinationLocation = item.destinationLocation || item.requestedDestinationLocation;
                item.courierOrderNumber = res.courierOrderNumber;
                item.isApproved = res.isApproved;
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        async createRoute(res, item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                for (let i = 0; i < res.routeMovements.length; i++) {
                    const routeMovement = res.routeMovements[i];
                    
                    var movement = {
                        departureLocationID: routeMovement.departureLocationID,
                        destinationLocationID: routeMovement.destinationLocationID,
                        dueDepartureOn: routeMovement.dueDepartureOn,
                        dueArrivalOn: routeMovement.dueArrivalOn,
                        bundleID: routeMovement.bundleID,
                        courierOrderIDs: [item.id]
                    }

                    await this.$BlitzIt.store.post('courier-movements', movement);

                    item.isPlanned = true;
                }
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        async getRouteSuggestionsAsync(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var res = await this.$BlitzIt.api.getById('route-suggestions', item.id, null, null);
                if (res.data.data != null) {
                    res.data.data.routeMovements.sort(firstBy(x => x.sortNumber));
                }
                return res.data.data;
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        async invoiceCourierOrder(item, items) {
            var data = { id: item.id, isBillRequest: true }

            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                await this.$BlitzIt.api.patch('courier-order-fulfilling', data);
                this.$BlitzIt.store.deleteLocal('client-orders', item.id, null);
                var ind = items.findIndex(x => x.id == item.id);
                if (ind >= 0) {
                    items.splice(ind, 1);
                }
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        async planDirectRoute(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var movement = {
                    departureLocation: item.departureLocation || item.requestedDepartureLocation,
                    departureLocationID: item.departureLocationID || item.requestedDepartureLocationID,
                    destinationLocation: item.destinationLocation || item.requestedDestinationLocation,
                    destinationLocationID: item.destinationLocationID || item.requestedDestinationLocationID,
                    dueDepartureOn: item.dueDate,
                    dueArrivalOn: item.dueDate,
                    courierOrderIDs: [item.id],
                    isPickup: item.isPickup
                };
                
                await this.$BlitzIt.store.post('courier-movements', movement);
                
                item.isPlanned = true;
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        async rejectCourierOrder(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var data = {
                    id: item.id,
                    rowVersion: item.rowVersion,
                    isApproved: false
                };

                var res = await this.$BlitzIt.store.patch('client-orders', data);
                item.rowVersion = res.rowVersion;
                item.isApproved = false;
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        // planDirectRoute(item) {
        //     item.loading = true;

        //     var movement = {
        //         departureLocation: item.departureLocation,
        //         departureLocationID: item.departureLocationID,
        //         destinationLocation: item.destinationLocation,
        //         destinationLocationID: item.destinationLocationID,
        //         dueDepartureOn: item.dueDate,
        //         dueArrivalOn: item.dueDate,
        //         courierOrderIDs: [item.id]
        //     };

        //     this.$BlitzIt.store.post(
        //         'courier-movements',
        //         movement)
        //             .then(() => {
        //                 item.isPlanned = true;
        //                 item.loading = false;
        //             })
        // },
        // planRouteVia(item) {
        //     var movement = item.movement;
        //     var order = item.order;

        //     if (movement.intermediateLocationID && 
        //         movement.departureLocationID != movement.intermediateLocationID &&
        //         movement.destinationLocationID != movement.intermediateLocationID) {
                
        //         var movementOne = this.copyDeep(movement);
        //         var movementTwo = this.copyDeep(movement);
            
        //         movementOne.destinationLocationID = movement.intermediateLocationID;
        //         movementTwo.departureLocationID = movement.intermediateLocationID;

        //         this.$BlitzIt.store.post(
        //             'courier-movements',
        //             movementOne)
        //             .then(() => {
        //                 //this.currentItem.courierMovements.push(res);
        //                 order.isPlanned = true;
        //             })

        //         this.$BlitzIt.store.post(
        //             'courier-movements',
        //             movementTwo)
        //             .then(() => {
        //                 order.isPlanned = true;
        //             })
        //     }
        //     else {
        //         console.log('hmming');
        //     }
            
        // },
        // getMovement(item) {
        //     var movement = {
        //         departureLocation: item.departureLocation,
        //         departureLocationID: item.departureLocationID,
        //         destinationLocation: item.destinationLocation,
        //         destinationLocationID: item.destinationLocationID,
        //         intermediateLocationID: null,
        //         dueDepartureOn: item.dueDate,
        //         dueArrivalOn: item.dueDate,
        //         isLastLeg: false,
        //         courierOrderIDs: [item.id]
        //     }

        //     return {
        //         movement: movement,
        //         order: item
        //     }
        // },
        // getApproveObj(item) {            
        //     return {
        //         id: item.id,
        //         rowVersion: item.rowVersion,
        //         preferStartDropoff: false,
        //         preferEndPickup: false,
        //         responderMessage: null,                                
        //         departureLocationID: item.requestedDepartureLocationID,
        //         departureLocation: item.requestedDepartureLocation,
        //         destinationLocationID: item.requestedDestinationLocationID,
        //         destinationLocation: item.requestedDestinationLocation,
        //         isPlanRouteRequest: true,
        //         isCreateMovementsRequest: true
        //     };
        // },
        // rejectCourierOrder(po) {                        
        //     this.$BlitzIt.store.patch(
        //         'client-orders',
        //         { id: po.id, isApproved: false, rowVersion: po.rowVersion }
        //     )
        //     .then(() => {                
        //         po.isApproved = false;
        //     })            
        // }
    }
}
</script>